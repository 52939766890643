

import { geoToH3 } from "h3-js";

var Position = {
    cell: "unknown",
        location: {
        lat: 0,
            lon: 0
    }
} ;

export function setPosition(lat, lon, res) {
        var Pos = {
            cell: geoToH3(lat, lon, res),
            location: {
                lat: lat,
                lon: lon
            }
        }
    Position = Pos;
    return Pos;
}


export default { Position }