<template>
  <transition name="fade" :duration="{ enter: 1000, leave: 500 }">
    <div id="outofbounds" v-if="outOfBounds"><h3><em class="noto">⚠️</em>&nbsp;POZOR&nbsp;<em class="noto">⚠️</em><br>Nachádzaš sa&nbsp;mimo hracej plochy</h3><span>Pre pokračovanie sa&nbsp;vráť do&nbsp;žilinských ulíc</span></div>
  </transition>
  <transition name="fade" :duration="{ enter: 1000, leave: 300 }">
    <div id="unknownposition" v-if="unknownPosition && !outOfBounds"><h3><img src="/assets/ring.gif" alt="waiting" /><br><br>Čakám na GPS</h3></div>
  </transition>
  <div id="map" v-show=!this.$parent.inGame></div>
</template>

<script>

import {Loader} from "@googlemaps/js-api-loader"
import {geoToH3, h3ToGeoBoundary, kRing as h3KRing} from 'h3-js'

import {setPosition} from "@/obj/Position";


export default {
  name: "Map",
  props: ['player','inGame'],
  emits: ["changePosition"],
  data() {
    return {
      outOfBounds: false,
      unknownPosition: true
    }
  },
  mounted() {

    var vm = this;


    var playerMarker;
    var currentLatitude, currentLongitude = 0;
    var previousCell;
    var interval1S;

    var zoom = 18;
    var h3res = 10;

    var fake = false;



    const mapLoader = new Loader({
      apiKey: "AIzaSyCl5umkVEaAyNONcjRz_F9R8sYwDK4YKxg",
      version: "weekly",
      language: "sk",
      libraries: ["geometry","drawing"]
    })

    function h3ToPolyline(h3idx) {
      let hexBoundary = h3ToGeoBoundary(h3idx)
      hexBoundary.push(hexBoundary[0])

      let arr = []
      for (const i of hexBoundary) {
        arr.push({lat: i[0], lng: i[1]})
      }

      return arr
    }




    mapLoader.load().then(() => {
      let map = new window.google.maps.Map(document.getElementById("map"), {
        mapId: "86d249b208f228f7",
        center: { lat: 49.223440, lng: 18.739398 },
        zoom: zoom,
        disableDefaultUI: true,
        //gestureHandling: "none",     // TODO: remove this for production
        zoomControl: false,
      });

      function handleCurrentPosition(position) {
          currentLatitude = position.coords.latitude;
          currentLongitude = position.coords.longitude;

      }

      function updateCurrentPosition() {
        if (fake) {
          currentLatitude = randomDecimal(49.18,49.25,7);
          currentLongitude = randomDecimal(18.7,18.8,7);
        }
          if (currentLatitude != 0 && currentLongitude != 0) {
            var curPosition = new window.google.maps.LatLng(currentLatitude, currentLongitude);
            playerMarker.setPosition(curPosition);
            map.panTo(curPosition);
            //map.setZoom(zoom);
            if (previousCell !== undefined) { setHexColorOpacity(previousCell, "black", 0); }
            var highlightCell = geoToH3(currentLatitude, currentLongitude, h3res);
            vm.unknownPosition = (vm.$parent.position.cell=="unknown");
            previousCell = highlightCell;
            vm.outOfBounds = (window["hexa_"+highlightCell] === undefined);
            setHexColorOpacity(highlightCell, "#000070", 0.3);
            vm.$emit('changePosition', setPosition(currentLatitude, currentLongitude, h3res));
          }

        return interval1S;
      }

      function setHexColorOpacity(h3cell, color, opacity) {
        if (window["hexa_"+h3cell] !== undefined) {
          window["hexa_"+h3cell].setOptions({ fillColor: color, fillOpacity: opacity });
        }
      }

      function randomDecimal(min, max, places) {
        let value = (Math.random() * (max - min )) + min;
        return Number.parseFloat(value).toFixed(places);
      }

    var hexagons = h3KRing(geoToH3(49.223440,18.739398, h3res), 80);

    for (let hexId = 0; hexId < hexagons.length; hexId++) {
      let hexName = 'hexa_' + hexagons[hexId];
      window[hexName] = new window.google.maps.Polygon({
        path: h3ToPolyline(hexagons[hexId]),
        geodesic: true,
        strokeColor: "#707070",
        strokeOpacity: 0.1,
        strokeWeight: 0.8,
        clickable: false,
        fillColor: "black",
        fillOpacity: 0

      });

      window[hexName].setMap(map);

    }

    // trying to get geolocation
      if (navigator.geolocation) {
        playerMarker = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(0,0),
          visible: true,
          title: "Player",
          icon: "/assets/ring.gif"
        });
        playerMarker.setMap(map);
        navigator.geolocation.watchPosition(handleCurrentPosition);
        // TODO handle gps errors
      }
      // TODO handle unavailable geolocation in browser

      interval1S = window.setInterval(updateCurrentPosition, 1000);
      return map;
    })

  }

}
</script>

<style lang="scss">

#map {
  width:100%;
  height:100%;
  position: absolute;
  top: 0px;
  left: 0px;
}

#outofbounds {
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: absolute;
  top: 0px;
  left: 0px;
  background: rgba(255,135,0,0.2);
  color: white;
  text-align: center;
  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000;

  h3 {
    margin-top: 3em;
    font-size: 2em;
  }
  span {
    margin-top: 2em;
    display: block;
  }
}

#unknownposition {
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: absolute;
  top: 0px;
  left: 0px;
  background: rgba(5,5,5,0.7);
  color: white;
  text-align: center;
  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000;

  h3 {
    margin-top: 3em;
    font-size: 2em;
  }
  span {
    margin-top: 2em;
    display: block;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s
}
.fade-enter, .fade-leave-to {
  opacity: 0
}
</style>