<template>
  <div class="explorePanel" v-show="!inGame"><div class="explore"><span class="noto"  v-on:click="explore">🔍</span></div></div>
  <div class="bottom-panel">
  <span class="left">{{player.Player.name }}</span> <span class="right"><span class="noto">⚡</span> {{  player.Player.inventory.energy }} <span class="noto">🍦</span> {{player.Player.inventory.icecream }}</span>
</div>
</template>

<script>



//import {setPosition} from "@/obj/Position";

export default {
  name: "Panel",
  props: ['player','inGame','position'],
  emits: ["exploreCell"],
  methods: {
    explore() {
      //console.log("explore: " + this.position.cell);
      this.$emit('exploreCell', this.position.cell);
    }
  }
}
</script>

<style scoped>

.bottom-panel {
  position: fixed;
  bottom: 0;
  background: #0D003F;
  width: 100%;
  text-align: center;
  padding: 8px 0;
color: #0088F8;
}

.left {
  float: left;
  margin-left: 1em;
}

.right {
  float: right;
  margin-right: 1em;
}

.explore {
  margin: 0 auto;
  display: block;
  background: #0A012D;
  left: 47%;
  border-radius: 50%;
  z-index: 999;
  color: #0088F8;
  width: 64px;
  height: 64px;
  font-size: 32px;
  border: 5px solid #0f1114;
  cursor: pointer;
}

.explorePanel {
  position: fixed;
  bottom: 8px;
  width: 100%;
  text-align: center;
  z-index: 999;
}
</style>