import api from "../api"

var state = {
    logged: false,
    token: 'NONE'
}

var callback;



function loginViaApi(code, cb) {
    var loginData = { code: code };
    callback = cb;
    api.postData("login", loginData, parseReplyFromApi);
}

function parseReplyFromApi(errorstate, content) {
    if (errorstate!==null)
        return callback(errorstate);

    if (content.status === 200) {
        var contentData = content.data;
        var tokenExists = Object.prototype.hasOwnProperty.call(contentData,'token');

        if (tokenExists) {
            state.token = content.data.token;
            state.logged = true;
            var cookieExpiration = new Date();
            cookieExpiration.setDate(cookieExpiration.getDate() + 14);
            document.cookie = "token=" + state.token + "; expires=" + cookieExpiration.toUTCString() + "; path=/";
            localStorage.setItem("token", state.token);
            localStorage.setItem("token_expiration", cookieExpiration.toISOString());
            return callback(state);
        }
    }
    return callback(state);

}

export default { state, loginViaApi }