import axios from "axios";

//const apiBase = "http://localhost:8081/"

const apiBase = "https://test-api.duuuuu.ch/"

function postData( route, data, callback ) {
    const error  = 'There was a problem posting data';


    axios.post( apiBase+route, data ).then( res => {
        if (res.status !== 200) return callback(error+ ": HTTP "+ res.status +" " + res.statusText);
        return callback( null, res );
    })
        .catch( e => {
            return callback( error+": " + String( e.message || '' ), [] );
        });
}

function getData( route, data, callback ) {
    const error  = 'There was a problem getting data';


    axios.get( apiBase+route, {params: data } ).then( res => {
        if (res.status !== 200) return callback(error+ ": HTTP "+ res.status +" " + res.statusText);
        return callback( null, res );
    })
        .catch( e => {
            return callback( error+": " + String( e.message || '' ), [] );
        });
}
export default { postData, getData }
