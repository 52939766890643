<template>
  <div class="container">
    <Map v-bind:player=playerData @changePosition="changePosition($event)"></Map>
    <Panel v-bind:player=playerData v-bind:inGame=inGame v-bind:position=position @exploreCell="exploreCell($event)"></Panel>

  </div>
</template>

<script>
// @ is an alias to /src
import Panel from "../components/Panel";
import Player from "../obj/Player";
import Position from "@/obj/Position";
import Map from "../components/Map";
import Explorer from "@/explorer";

export default {
  name: 'Home',
  components: {
    Panel, Map
  },
  methods: {
    changePosition(position) {
      this.position = position;
    },
    exploreCell(cellId) {
      Explorer.explore(cellId, this.handleExplorationResult);
    },
    handleExplorationResult(content) {
      if (content.ok) {
        var explorationContains = content.content;
        switch (explorationContains.contains) {
          case "energy":
            this.playerData.Player.inventory.energy += explorationContains.amount;
            break;
          case "icecream":
            this.playerData.Player.inventory.icecream += explorationContains.amount;
            break;
          case "game":
            var gameSeed = explorationContains.seed;
            this.$router.push({path: `/game/${gameSeed}` })
            break;
          default:
            console.log("unknown content");
        }
      }
    }
  },
  data() {
    return {
      playerData: Player,
      inGame: false,
      position: Position
    }
  }
}
</script>
