import api from "../api"

var callback;
//var explorationResult;

function explore(cellId, resultResolver) {
    var exploreCell = { cell: cellId };
    callback = resultResolver;
    api.getData("explore", exploreCell, exploreCallback);
}

function exploreCallback(errorState, content) {
    if (errorState == null) {
        callback({"ok": true, "content": content.data});
    } else {
        callback({"ok": false, "error": errorState});
    }
}

export default { explore }