<template>
  <div class="gb-container">
    <div class="gameboard" id="gb"></div>
  </div>
</template>

<script>
//import Player from "../obj/Player";

export default {
  name: "GameBoard",
  props: ["player", "seed"],
  data() {
    return {

    }
  },
  updated() {
    var currentSeed = JSON.parse(atob(this.seed));

    var board = document.getElementById("gb");
    var size_x = currentSeed.x;
    var size_y = currentSeed.y;
    var blacks = currentSeed.b;
    var numbers = currentSeed.n;

    for (var y=1;y<=size_y;y++) {
      var gameBoardRow = document.createElement("div");
      gameBoardRow.setAttribute("class","prow");
      for (var x=1;x<=size_x;x++) {
        var elementName = "gb_"+y+"_"+x;
        var gameBoardPiece = document.createElement("div");
        gameBoardPiece.setAttribute("id",elementName);
        var classString = "piece ";
        var pieceId = y + "-" + x;
        gameBoardPiece.onclick = function(e) {
          clickPiece(e);
        }
        if (blacks.includes(pieceId)) classString += "pblack ";
        gameBoardPiece.setAttribute("class",classString);
        if (numbers[pieceId]!==undefined) {
          var pieceNumber = document.createTextNode(numbers[pieceId]);
          gameBoardPiece.appendChild(pieceNumber);
        }
        gameBoardRow.appendChild(gameBoardPiece);
      }
      board.appendChild(gameBoardRow);
    }

    function clickPiece(e) {
      var piece = e.target.id;
      var pieceSplit = piece.split("_");
      var pieceX = parseInt(pieceSplit[2]);
      var pieceY = parseInt(pieceSplit[1]);
      var pieceElement = document.getElementById(piece);
      var pieceClasses = pieceElement.classList;

      if (pieceClasses.contains("pblack")) return; // ignore black cells clicks
      if (pieceClasses.contains("plight")) return; // ignore illuminated cells clicks
      if (pieceClasses.contains("plightbulb")) {
        pieceElement.classList.remove("plightbulb"); // remove lightbulb
        lightUpBoard(); // refresh whole board
        return;
      }

      // find numbers and their lightbulbs around
      var surroundingNumbers = [
        checkPieceContent(pieceX,pieceY-1),
        checkPieceContent(pieceX+1, pieceY),
        checkPieceContent(pieceX, pieceY+1),
        checkPieceContent(pieceX-1, pieceY)];
      for (var sCount = 0;sCount<surroundingNumbers.length;sCount++) {
        var surrounding = surroundingNumbers[sCount];
        if (surrounding!==null) {
          for (var element in surrounding) {
            var elementMaxCount = surrounding[element];
            var elementSplit = element.split("_");
            var elementX = parseInt(elementSplit[2]);
            var elementY = parseInt(elementSplit[1]);

            var surroundingLightbulbs = [
              checkPieceLightbulb(elementX,elementY-1),
              checkPieceLightbulb(elementX+1, elementY),
              checkPieceLightbulb(elementX, elementY+1),
              checkPieceLightbulb(elementX-1, elementY)];
            var elementCount = 0;
            for (var lCount = 0;lCount<surroundingLightbulbs.length;lCount++) {
              if (surroundingLightbulbs[lCount] !== null) elementCount++;
            }
            if (elementCount >= elementMaxCount) {
              // maximum lighbulbs reached
              return;
            }
          }
        }
      }


      // ray top
      for (var lightYUp = pieceY-1; lightYUp > 0; lightYUp--) {
        var lightIdUp = "gb_"+lightYUp+"_"+pieceX;
        if (document.getElementById(lightIdUp)!==null) {
          var lightElementUp = document.getElementById(lightIdUp);
          if (lightElementUp.classList.contains("pblack")) break;  // stop ray on black
          if (lightElementUp.classList.contains("plightbulb")) return; // stop click handling on lightbulb
        } else {
          break; // stop ray on wall
        }
      }
      // ray down
      for (var lightYDown = pieceY+1; lightYDown < size_y; lightYDown++) {
        var lightIdDown = "gb_"+lightYDown+"_"+pieceX;
        if (document.getElementById(lightIdDown)!==null) {
          var lightElementDown = document.getElementById(lightIdDown);
          if (lightElementDown.classList.contains("pblack")) break;  // stop ray on black
          if (lightElementDown.classList.contains("plightbulb")) return; // stop click handling on lightbulb
        } else {
          break; // stop ray on wall
        }
      }
      // ray right
      for (var lightXRight = pieceX+1; lightXRight < size_x; lightXRight++) {
        var lightIdRight = "gb_"+pieceY+"_"+lightXRight;
        if (document.getElementById(lightIdRight)!==null) {
          var lightElementRight = document.getElementById(lightIdRight);
          if (lightElementRight.classList.contains("pblack")) break;  // stop ray on black
          if (lightElementRight.classList.contains("plightbulb")) return; // stop click handling on lightbulb
        } else {
          break; // stop ray on wall
        }
      }
      // ray left
      for (var lightXLeft = pieceX-1; lightXLeft > 0; lightXLeft--) {
        var lightIdLeft = "gb_"+pieceY+"_"+lightXLeft;
        if (document.getElementById(lightIdLeft)!==null) {
          var lightElementLeft = document.getElementById(lightIdLeft);
          if (lightElementLeft.classList.contains("pblack")) break;  // stop ray on black
          if (lightElementLeft.classList.contains("plightbulb")) return; // stop click handling on lightbulb
        } else {
          break; // stop ray on wall
        }
      }

      pieceElement.classList.add("plightbulb"); // add lightbulb todo rows
      lightUpBoard(); // refresh whole board

    }

    function lightUpBoard() {
      var bulbs = getLightbulbs();
      var light = []; // elements to be lighted up

        for (var bulb = 0; bulb < bulbs.length; bulb++) {

          var bulbSplit = bulbs[bulb].split("_");
          var bulbX = parseInt(bulbSplit[2]);
          var bulbY = parseInt(bulbSplit[1]);

          //ray up
          for (var rU = bulbY-1; rU > 0; rU--) {
            var upElemId = "gb_"+rU+"_"+bulbX;
            if (document.getElementById(upElemId)!==null) {
              var upElem = document.getElementById(upElemId);
              if (upElem.classList.contains("pblack")) break;
              light.push(upElemId);
            }
          }

          //ray down
          for (var rD = bulbY+1; rD <= size_y; rD++) {
            var downElemId = "gb_"+rD+"_"+bulbX;
            if (document.getElementById(downElemId)!==null) {
              var downElem = document.getElementById(downElemId);
              if (downElem.classList.contains("pblack")) break;
              light.push(downElemId);
            }
          }


          //ray right
          for (var rR = bulbX+1; rR <= size_x; rR++) {
            var rightElemId = "gb_"+bulbY+"_"+rR;
            if (document.getElementById(rightElemId)!==null) {
              var rightElem = document.getElementById(rightElemId);
              if (rightElem.classList.contains("pblack")) break;
              light.push(rightElemId);
            }
          }

          //ray left
          for (var rL = bulbX-1; rL > 0; rL--) {
            var leftElemId = "gb_"+bulbY+"_"+rL;
            if (document.getElementById(leftElemId)!==null) {
              var leftElem = document.getElementById(leftElemId);
              if (leftElem.classList.contains("pblack")) break;
              light.push(leftElemId);
            }
          }

        }

        // repaint gameboard
      for (var gbY = 1; gbY <= size_y; gbY++) {
        for (var gbX = 1; gbX <= size_x; gbX++) {
          var gbLightName = "gb_"+gbY+"_"+gbX;
          if (light.includes(gbLightName)) {
            document.getElementById(gbLightName).classList.add("plight");
          } else {
            document.getElementById(gbLightName).classList.remove("plight");
          }
        }
      }

    }

    function getLightbulbs() {
      var lightbulbs = [];
      var elements = document.getElementsByClassName("plightbulb");
      for (var elem = 0; elem < elements.length; elem++) {
        if (elements[elem].id !== null) lightbulbs.push(elements[elem].id);
      }
      return lightbulbs;
    }

    function checkPieceContent(x,y) {
      if (document.getElementById("gb_"+y+"_"+x) !== null) {
        var content = document.getElementById("gb_"+y+"_"+x).innerText;

        if (content != "") {
          var checkedIdName = "gb_"+y+"_"+x;
          var returnArray = [];
          returnArray[checkedIdName] = parseInt(content);
          return returnArray;
        }
        return null;
      }
      return null;
    }

    function checkPieceLightbulb(x,y) {
      if (document.getElementById("gb_"+y+"_"+x) !== null) {
        if (document.getElementById("gb_"+y+"_"+x).classList.contains("plightbulb")) return 1;
        return null;
      }
      return null;
    }

  }
}
</script>

<style >

.gb-container {
  text-align: center;
  width: 100%;
}

.piece {
  width: 48px;
  height: 48px;
  border: 1px solid #0f1114;
  background: ghostwhite;
  display: inline-block;
  vertical-align: top;
}

.plightbulb {

  color: orangered;
  background: yellow;
}

.plight {
  background: yellow;
}

.plightbulb:before {
  content: "\1F4A1";
  font-family: "Noto Emoji";
  font-size: 2em;
}

.pblack {
  background: #0f1114;
  color: white;
  font-size: 2em;
  text-align: center;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.prow {
  margin: 0;
  padding: 0;
  border: 0;
  height: 50px;
}


.gameboard {
  margin: 0 auto;
  width: 100%;
  margin-top: 2em;
}
</style>