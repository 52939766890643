var Player;

Player = {
    name: "Hráč",
    id: "4cSGTI7CjHunB-raDdXee",
    inventory: {
        energy: 5,
        icecream: 0
    }
};

export default { Player }